<template>
  <div class="methodology">
    <div class="info-card methodology-card">
      <h2><i class="fas fa-calculator"></i> Methodology</h2>
      <p class="methodology-info">
        <i class="fas fa-info-circle"></i> This page explains the methodology used to calculate the yearly carbon emissions.
      </p>
      <div class="methodology-steps">
        <h3><i class="fas fa-list-alt"></i> Methodology:</h3>
        <ol>
          <li>
            <b>Average Daily Fuel Consumption:</b>
            <p>504,373 liters of gasoline</p>
            <p>33,567 liters of diesel</p>
          </li>
          <li>
            <b>Emission Factors by Day:</b>
            <ul>
              <li>Monday: 1</li>
              <li>Tuesday: 1.08</li>
              <li>Wednesday: 0.5</li>
              <li>Thursday: 1.14</li>
              <li>Friday: 0.95</li>
              <li>Saturday: 1</li>
              <li>Sunday: 0.8</li>
            </ul>
          </li>
          <li>
            <b>Carbon Emission Factors:</b>
            <ul>
              <li>2.419 kg CO₂e per liter for gasoline</li>
              <li>2.735 kg CO₂e per liter for diesel</li>
            </ul>
          </li>
          <li>
            <b>Calculation Method:</b>
            <p>For each day of the year, we multiply the daily fuel consumption by the respective day's emission factor and the carbon emission factor. Summing these daily emissions and converting from kilograms to tons gives the total yearly carbon emissions. This method accounts for daily consumption variations, providing an accurate yearly estimate.</p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Methodology",
  data() {
    return {
      averageDailyGasolineConsumption: 504373,
      averageDailyDieselConsumption: 33567,
      dayFactors: {
        'Monday': 1,
        'Tuesday': 1.08,
        'Wednesday': 0.5,
        'Thursday': 1.14,
        'Friday': 0.95,
        'Saturday': 1,
        'Sunday': 0.8,
      },
      carbonEmissionFactorGasoline: 2.419,
      carbonEmissionFactorDiesel: 2.735,
    };
  },
};
</script>

<style scoped>
.methodology {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.info-card {
  flex: 1 1 60%;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.methodology-card {
  border-left: 8px solid #ff5722;
}

.methodology-info {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.methodology-steps {
  margin-top: 20px;
}

.methodology-steps h3 {
  font-size: 22px;
  color: #d32f2f;
}

.methodology-steps ol {
  list-style: none;
  padding-left: 0;
}

.methodology-steps ol li {
  margin-top: 10px;
}

.methodology-steps ol li p,
.methodology-steps ol li ul {
  margin-left: 20px;
}

.methodology-steps ol li ul li {
  list-style-type: disc;
  margin-left: 20px;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');
</style>

